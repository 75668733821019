import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

function useRoutePath() {
  const currentPathname = usePathname();
  const previousPathname = useRef<string | null>();

  useEffect(() => {
    previousPathname.current = currentPathname;
  }, [currentPathname]);

  return { previousPathname: previousPathname.current, currentPathname };
}

export default useRoutePath;
